import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet-async";


const Whitepaper = () => {
return (
<div className="tw-bg-center tw-relative tw-overflow-x-hidden tw-bg-black tw-bg-cover tw-w-full lg:tw-h-screen tw-h-auto">

<Helmet>
<title>EBM White Paper-Sustainable Approach to Decentralized Finance, Crypto</title>
<meta name="description" content="Explore EBM Avenue's vision for decentralized crypto mining, including Swap4Less, Stake4More, and Interest Free Loans. Join us in supporting sustainable practices and innovative tokenomics." />
<meta name="keywords" content="EBM Avenue, decentralized finance, 100x crypto, Best crypto, all-time crypto, Swap4Less, Stake4More, Interest Free Loans, sustainable crypto, UNGC, tokenomics, roadmap, EBM Avenue, crypto loans, discounts, cryptocurrency, sustainability, innovation, eco-friendly, Best Tokenomics, $EBM, transparency, fairness, value creation, UNGC, clean energy, climate change" />
<meta property="og:title" content="EBM Avenue White Paper - Future of Decentralized Finance and Eco friendly crypto mining " />
<meta property="og:description" content="Discover EBM Avenue's initiatives in decentralized crypto mining, featuring Swap4Less, Stake4More, and Interest Free Loans. Embrace sustainable practices and innovative tokenomics." />
<meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" />
<meta property="og:url" content="https://ebmavenue.io/whitepaper" />
</Helmet>
<Header />
<div className=" tw-absolute tw-top-0  tw-left-0">
<img src={require("../../assets/images/left_image.png")} alt="EBM Avenue brand color from the left" />
      </div>
      <div className=" tw-absolute tw-top-0 tw-right-0">
        <img src={require("../../assets/images/right_image.png")}  alt="aqua color brand lighting from right" />
      </div>

<h1 className="tw-text-center tw-text-white gradient-text tw-font-semibold tw-text-[45px] tw-mb-2">
EBM White Paper
</h1>

<div className="container tw-pt-5 tw-mx-auto tw-max-w-screen-lg col-md-12">

<iframe
src="/EBM-Whitepaper.pdf"
width="100%"
height="700px"
style={{ border: "none" }}
title="White Paper"
></iframe>


</div>
<Footer />
</div>
);
};
export default Whitepaper;