import React from "react";
import Header from "../../components/header";

import Footer from "../../components/footer";
import { Helmet } from "react-helmet-async";

const Swap4Less = () => {
  return (
    <div className=" tw-bg-center tw-relative  tw-overflow-x-hidden tw-bg-black tw-bg-cover tw-w-full lg:tw-h-screen tw-h-auto">
      <Helmet>
      <title>Swap4Less: Eco-Friendly Crypto Mining & Discounted Cryptocurrency</title>
<meta name="description" content="Discover EBM Avenue's Swap4Less program offering 5-10% discounts on cryptocurrencies for EBM token holders. Join our eco-friendly crypto mining revolution and enjoy exclusive rewards through our swap4less program."/>
<meta name= "keywords" content = "EBM Avenue, eco-friendly crypto mining, Swap4Less, discounted crypto currency, EBM token, Stake4More, cryptocurrency discounts, sustainable crypto, crypto rewards" />
<meta property="og:type" content="website"/> <meta property="og:url" content="https://ebmavenue.io/swap4less"/> 
<meta property="og:title" content="Swap4Less: EBM Avenue's Affordable Swapping Service"/>
<meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png"/>
<meta property="og:description" content="Discover EBM Avenue's Swap4Less for discounted and efficient token swaps."/>
      </Helmet>
      <Header />
      <div className=" tw-absolute tw-top-0 tw-left-0">
        <img src={require("../../assets/images/left_image.png")} alt = "light shade from left"/>
      </div>
      <div className="container  tw-py-28">
        <div className="row  g-5  tw-items-center">
          <div className="col-md-6">
            <h1 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[45px]">
            Swap 4 less            </h1>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            In a world where cryptocurrency prices are constantly fluctuating, EBM Avenue stands out by offering exclusive discounts to its loyal community. Our innovative 'Swap4Less' program is designed to reward $EBM token holders with up to 10% off on cryptocurrencies we mine.
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Program Details            </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            The discounts are calculated based on the average number of $EBM tokens you hold each month. The more tokens you hold, the greater your purchasing power and the higher your discount. Plus, by participating in our 'Stake4More' program, you can unlock even more benefits and rewards.
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Benefits            </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Our commitment to eco-friendly crypto mining ensures that you are not only getting great deals but also supporting sustainable practices. <br></br>
            Join us in revolutionizing the crypto space with EBM Avenue's Swap4Less program!
            </p>
            </div>
            <div className="col-lg-6 col-md-12">
              <a href="https://x.com/EBMAvenueLLC" target="_blank" rel="noopener noreferrer"/>
                <img
                  src={require("../../assets/images/SWAP4LESS.webp")}
                  alt = "swap4less benefits"
                  className=" tw-w-full"
                />
              </div>

            
           
          
          
             
            </div>
            
           </div>
           <Footer/>
          </div>
      
      
      
    
  );
};

export default Swap4Less;
