import React from "react";


const Tokenomics = () => {
  return (
    
  <div
  id="tokenSection"className="tw-bg-Token tw-py-14 tw-flex tw-items-center tw-justify-center tw-h-auto tw-mb-0" >
    
  <div className="container">
  <div className="row">
  <div className="col-md-11 tw-mx-auto tw-text-center tw-mb-0">
  <h2 className="tw-text-white gradient-text tw-font-semibold tw-text-4xl">
  TOKENOMICS
  </h2>
 
  <img src={require("../../assets/images/tokens.webp")} alt="Pie chart" />
  <br></br>
  <h3 className="tw-text-white gradient-text tw-font-poppins tw-font-extrabold tw-text-[35px]">
  10 Billion
  </h3>
  <p className="tw-text-white tw-text-lg">Total Supply</p>
  <p className="tw-text-white tw-text-lg">
  EBM's best tokenomics model shows how we value transparency, fairness, and value creation such that our tokens become invaluable assets within our ecosystem and beyond it.
  </p>
  
</div>
</div>
  
  <div  id="Stages" className="tw-py-14 tw-flex tw-items-center tw-justify-center tw-mt-0">
  <div className="container tw-max-w-screen-lg tw-mx-auto">
  
  <h2 className="tw-text-white gradient-text text-center tw-font-poppins tw-font-extrabold tw-text-[35px]"> Presale - Your Choice </h2>
  <h3 className=" gradient-text tw-text-3xl text-center"> Buy & Claim - OR - Buy & Stake </h3>
  <p className="tw-text-white tw-text-center"> EBM Presale is designed to have NO fear of missing out (FOMO), will last untill the supply exist in each stage</p>
  <p className="tw-text-white tw-text-center"> 40 % of Total supply has been allocated for the Presale to build EBM Community with Bonus upto 15% with exclusive Staking </p>
  <br></br>
  {/*<p className="tw-text-white tw-text-center"> And an Extra 2-5 % of $EBM tokens can be availed for investing above 200 USD  </p>*/}
  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-5 gap-4 tw-items-center">
    <div className="tw-flex tw-flex-col tw-items-center p-4 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[180px]">
      <h2 className="gradient-text tw-text-3xl">Stage 1</h2>
      <p className="tw-text-white tw-text-center"> 20 % of Presale</p>
      
      <h3 className="tw-text-white tw-text-xl">$EBM = $0.0012</h3>
    
      <h3 className="gradient-text tw-text-xl"> Next  + 16.67 % </h3>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center p-4 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[180px]">
      <h2 className="gradient-text tw-text-3xl">Stage 2</h2>
      <p className="tw-text-white tw-text-center"> 30 % of Presale</p>
      <h3 className="tw-text-white tw-text-xl">$EBM = $0.0014</h3>
      
      <h3 className="gradient-text tw-text-xl">Next + 14.29 %</h3>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center p-4 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[180px]">
      <h2 className="gradient-text tw-text-3xl">Stage 3</h2>
      <p className="tw-text-white tw-text-center"> 35 % of Presale</p>
      
      <h3 className="tw-text-white tw-text-xl">$EBM = $0.0016</h3>
      
      <h3 className="gradient-text tw-text-xl">Next + 12.50 %</h3>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center p-4 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[180px]">
    <h2 className="gradient-text tw-text-3xl">Stage 4</h2>
    <p className="tw-text-white tw-text-center"> 15 % of Presale</p>
    
    <h3 className="tw-text-white tw-text-xl">$EBM = $0.0018</h3>
    
    <h3 className="gradient-text tw-text-xl">Next + 11.11 %</h3>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center p-4 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[180px]">
    <h2 className="gradient-text tw-text-3xl">DEX / CEX</h2>
    <h2 className="gradient-text tw-text-2xl">Launch Price</h2>
    
    <h3 className="tw-text-white tw-text-xl">$EBM = $0.002</h3>
    
    <h3 className="gradient-text tw-text-xl"> 66.6 %</h3>
    </div>
    
  
  </div>
  <div className="container tw-max-w-screen-lg tw-mx-auto tw-mb-0">
    <br></br>
    <p className="tw-text-white tw-text-center"> <span className="gradient-text">Get Bonus of 2-5 % </span>of $EBM tokens when spending 200 USD and above </p>
    <p className="tw-text-white tw-text-center"> <span className="gradient-text">Extra Bonus of 4-15 % </span>with exclusive Buy & Stake option. </p>
    <p className="tw-text-white tw-text-center"> <span className="gradient-text">*</span> Unstake anytime without penalty <span className="gradient-text">*</span> Claim rewards anytime <span className="gradient-text">*</span> But cannot stake again! </p>
    <p className="tw-text-white tw-text-center"> Unsold & Unused tokens from presale and staking will be reallocated to <span className="gradient-text">Bridge liquidity lock </span></p>
    <p className="tw-text-white tw-text-center tw-text-xl"> <span className="gradient-text">53 %</span> of raised funds will be added to DEX/CEX liquidity </p>
    </div>
  </div>
  </div></div>
  </div>
  
  
  )
  };
  
  export default Tokenomics;
