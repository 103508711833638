import React, { useState } from "react";

const VideoPlayer = ({ src, thumbnail }) => {
const [isVideoLoaded, setIsVideoLoaded] = useState(false);

const isYouTubeLink = typeof src === "string" && (src.includes("youtube.com") || src.includes("youtu.be"));

const loadVideo = () => {
setIsVideoLoaded(true);
};

return (
<div className="video-player" style={{ position: "relative", cursor: "pointer" }}>
{isYouTubeLink && !isVideoLoaded ? (
<img
src={thumbnail}
alt="Video thumbnail"
style={{ width: "100%", borderRadius: "10px" }}
onClick={loadVideo}
/>
) : isYouTubeLink ? (
<iframe
width="100%"
height="315"
src={src}
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
></iframe>
) : (
<video controls src={src} style={{ width: "100%", borderRadius: "10px" }} />
)}
</div>
);
};

export default VideoPlayer;