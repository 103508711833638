import React from 'react'

import EBM_Avenue from '../EBM_avenue'
import TokenUtility from '../TokenUtility'

const Aboutus = () => {
  return ( <div>
    
    
  <div id='aboutSection' className='tw-overflow-x-hidden tw-bg-Mesh tw-bg-contain tw-relative tw-py-20 tw-bg-[#0a0a0a] tw-w-full tw-h-auto tw-mb-0 tw-pb-0'>
  <div className="tw-absolute tw-top-0 tw-right-0">
  <img src={require("../../assets/images/right_image.png")} alt="Right shade" />
  </div>
  <div className='container tw-mb-0 tw-pb-0'>
  <div className='row g-5 tw-items-center'>
  <div className='col-lg-6 col-md-12 tw-mb-0 tw-pb-0'>
  <h2 className="tw-text-white gradient-text tw-font-semibold md:tw-text-[45px] tw-text-[35px]">
  Our Core Values & Commitment to Global Sustainability Initiatives
  </h2>
  <p className="tw-text-white sm:tw-text-start tw-text-center tw-leading-7 tw-pt-5 tw-text-xl">
  At EBM Avenue LLC, our core values are deeply rooted in sustainability, innovation, and inclusivity. As a forward-thinking company dedicated to advancing eco-friendly cryptocurrency solutions and promoting clean energy, we believe that businesses must play a leading role in shaping a sustainable future for all. Our commitment to these principles is reflected in our active participation in several global initiatives aimed at addressing critical environmental, social, and economic challenges.
  </p>
  </div>
  <div className='col-lg-6 col-md-12'>
  <img src={require('../../assets/images/mash_image.png')} className='tw-w-full tw-mb-0 tw-pb-0' alt="EBM Avenue's sustainable defi" />
  </div>
  </div>
  <div >
        <p className=" tw-text-white  sm:tw-text-start tw-text-center  tw-leading-7 tw-pt-4 tw-text-xl">
            We proudly support the United Nations Global Compact (UNGC) and its ten principles, promoting human rights, labor standards, environmental sustainability, and anti-corruption. Our alignment with the Sustainable Development Solutions Network (SDSN) further strengthens our resolve to contribute to the global effort to achieve the UN's Sustainable Development Goals (SDGs) through our innovative business practices.
        </p>
        <p className=" tw-text-white  sm:tw-text-start tw-text-center  tw-leading-7 tw-pt-4 tw-text-xl">
            Additionally, we are fully committed to supporting all 17 of the UN Sustainable Development Goals, integrating them into our strategies to drive positive change across areas such as poverty reduction, gender equality, clean energy, economic growth, and climate action. By doing so, we aim to contribute meaningfully to building a more sustainable and equitable future for all.
        </p>
        
   </div>
 
    </div>
    <div className='tw-overflow-x-hidden tw-bg-Mesh tw-bg-contain tw-relative tw-bg-[#0a0a0a] tw-w-full tw-h-auto tw-mb-0 tw-pb-0'>
   <div className='container tw-mb-0 tw-pb-0'>
  <div className='row g-5 tw-items-center'>
  <div className='col-lg-6 col-md-12 tw-flex tw-items-start tw-space-x-2' >
  <img src={require('../../assets/images/ungcwep.png')}  className='tw-w-full tw-mb-0 tw-pb-0' alt="Man and Women at EBM Avenue's solar farm showing Gender equality " />
  </div>
  <div className='col-lg-6 col-md-12 tw-mb-0 tw-pb-0'>
  <p className=" tw-text-white sm:tw-text-start tw-text-center  tw-leading-7 tw-pt-4 tw-text-xl">
            As a member of the UN Environment Programme Finance Initiative (UNEP FI), we integrate environmental and social considerations into our financial operations, ensuring that our eco-friendly solutions within the cryptocurrency industry align with global sustainability standards. We are committed to reducing carbon emissions and fostering green technology to combat climate change.
            <br></br><br></br>
 In promoting gender equality, we are committed to the UN Women’s Empowerment Principles (WEPs), advocating for equal opportunities and the empowerment of women across our business operations. We strive to create an inclusive and diverse workplace that supports and uplifts women, fostering innovation and growth.
 
  </p>
  </div>
  </div>
  <div >
<p className=" tw-text-white sm:tw-text-start tw-text-center  tw-leading-7 tw-pt-4 tw-text-xl">
        Finally, our dedication to human rights is reflected in our alignment with various Human Rights Initiatives, ensuring that our business activities respect and protect the rights of all individuals involved in our operations, from employees to stakeholders. We aim to create a fair and equitable environment where all people can thrive. 
        
        Through these partnerships and initiatives, EBM Avenue LLC is committed to leading by example, leveraging core values to build a sustainable and equitable future for all.  
        </p>
</div>
</div>
   </div>

      <EBM_Avenue/>
      <TokenUtility/>
      </div> 
    </div>
  )
}

export default Aboutus
